.footer {
    width: 100%;
    height: 60vh;
    position: relative;
    background:rgba(255, 255, 255, 0.7); 
}

.footer:before {
    content: '';
    position: absolute;
    background: url('../../assets/footer.png')no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.footer .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.footer .top {
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer .top .icon {
    font-size: 3rem;
    color: var(--primary-color);
    margin-right: 1rem;
    cursor: pointer;
}

.footer .top .icon:hover {
    color: var(--button-h);
  }

.footer .top .logo-footer {
    display: flex;
    align-items: center;
}

.footer h2 {
    font-size: 2rem;
}

.footer .col-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 2fr;
    padding: 1rem;
    width: 100%;
}

.footer h3 {
    margin:  1rem 0 2rem 0;
    text-transform: uppercase;
}

.footer p {
    margin: .5rem 0;
}

.footer form {
    width: 100%;
    margin-bottom: .6rem;
    position: relative;
}

.footer form input {
    padding: 1rem;
    width: 100%;
    outline: none;
    font-size: 1rem;
    color: var(--primary-color);
}

.footer form input:focus {
    color: var(--primary-color);
    outline: var(--primary-color);
    box-shadow: 0 0 8px 0 var(--primary-color);
}

.footer form .mail-icon {
    position: absolute;
    font-size: 1.4rem;
    margin: 1rem;
    right: .2rem;
    color: var(--primary-color);
    cursor: pointer;
}

.footer form .mail-icon:hover {
    color: var(--button-h);
  }

.footer form .social-group {
    display: flex;
    justify-content: space-between;
}

.footer form .social-icon {
    margin: 1rem;
    padding: .3rem;
    background-color: var(--primary-color);
    border-radius: 100%;
    font-size: 2.5rem;
    color: #eee;
    cursor: pointer;
}

.footer form .social-icon:hover {
    background-color: var(--button-h);
    border: 1px solid var(--button-h);
  }

@media screen and (max-width: 940px) {
    .footer {
        height: 100%;
    }

    .footer .col-container {
        grid-template-columns: 2fr 2fr;
    }

    .footer form {
        grid-column: 1 / span 2;
    }
}





