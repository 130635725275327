.cloud {
    color: #eee;
    width: 100%;
    height: 100vh;
    position: relative;
    background: rgba(0, 0, 0, .1);
}

.cloud::before {
    content: '';
    position: absolute;
    background: url('../../assets/cloud.png') no-repeat center center/cover;
    height: 100%;
    width: 100%;
    z-index: -1;
}

.cloud .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    right: 45%;
    top: 30%;
    width: 50%;
}

.cloud .content p {
    margin: 1rem 0;
}

@media screen and (max-width: 940px) {
    .cloud .content {
       left: 0;
       width: 100%;
       padding: 2rem;
       background: rgba(46, 47, 48, 0.7);
    }
  }

